import React, { Component } from "react";
import "./style.scss";
import logo from "../../images/A&D Studio Logo_475x310.jpg";
import { Link } from "gatsby";

class NavbarMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navShow: false,
      dd1Toggle: false,
      dd2Toggle: false,
    };

    this.handleToggleClick = this.handleToggleClick.bind(this);
    this.handleToggleDropdown1 = this.handleToggleDropdown1.bind(this);
    this.handleToggleDropdown2 = this.handleToggleDropdown2.bind(this);
  }

  handleToggleClick() {
    this.setState((state) => {
      return { navShow: !state.navShow };
    });
  }

  handleToggleDropdown1() {
    // alert("hello");
    this.setState((state) => {
      return { dd1Toggle: !state.dd1Toggle };
    });
  }

  handleToggleDropdown2() {
    this.setState((state) => {
      return { dd2Toggle: !state.dd2Toggle };
    });
  }

  render() {
    return (
      <div className="navbarmobile">
        <div
          className={`navbarmobile__background ${
            this.state.navShow && "navbarmobile__background--show"
          }`}
        ></div>

        <a href="/">
          <img className="navbarmobile__logo" src={logo}></img>
        </a>
        <div className="navbarmobile__back"></div>
        <button
          className={`hamburger hamburger--elastic ${
            this.state.navShow && "is-active"
          } navbarmobile__toggler`}
          type="button"
          aria-label="Menu"
          aria-controls="navigation"
          onClick={this.handleToggleClick}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner makewhite"></span>
          </span>
        </button>

        <div
          className={`navbarmobile__links ${
            this.state.navShow && "navbarmobile__links--show"
          }`}
        >
          <ol>
            <li className="navbarmobile__dropdown">
              <a
                onClick={this.handleToggleClick}
                onKeyDown={this.handleToggleClick}
                href="/#about"
              >
                About
              </a>
              <div
                className={`navbarmobile__dropdown__down ${
                  this.state.dd1Toggle && "navbarmobile__dropdown__down--active"
                }`}
                onClick={this.handleToggleDropdown1}
              >
                &#x2304;
              </div>
              <div
                className={`navbarmobile__dropdown__content ${
                  this.state.dd1Toggle &&
                  "navbarmobile__dropdown__content--active"
                }`}
              >
                <div className="navbarmobile__dropdown__content__link">
                  <a
                    onClick={this.handleToggleClick}
                    onKeyDown={this.handleToggleClick}
                    href="/team/"
                  >
                    team
                  </a>
                </div>
                <div className="navbarmobile__dropdown__content__link">
                  <a
                    onClick={this.handleToggleClick}
                    onKeyDown={this.handleToggleClick}
                    href="/milestones/"
                  >
                    milestones
                  </a>
                </div>
              </div>
            </li>
            <li>
              <a
                onClick={this.handleToggleClick}
                onKeyDown={this.handleToggleClick}
                href="/#clients"
              >
                Clients
              </a>
            </li>
            <li>
              <a
                onClick={this.handleToggleClick}
                onKeyDown={this.handleToggleClick}
                href="/projects"
              >
                Projects
              </a>
            </li>
            <li>
              <a
                onClick={this.handleToggleClick}
                onKeyDown={this.handleToggleClick}
                href="/#connect"
              >
                Connect
              </a>
            </li>
          </ol>
        </div>
      </div>
    );
  }
}

export default NavbarMobile;

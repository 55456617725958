import React, { Component } from "react";
import "./header.scss";

import logo from "../images/A&D Studio Logo_475x310.jpg";
import { Link } from "gatsby";
import $ from "jquery";
import NavbarMobile from "../components/navbarmobile";

class Header extends Component {
  componentDidMount() {
    const header = document.querySelector(".header");

    if (!this.props.isWhite) {
      window.onscroll = () => {
        if (window.scrollY > 70) {
          header.classList.add("header--white");
        } else {
          header.classList.remove("header--white");
        }
      };
    }
  }
  render() {
    return (
      <header>
        <div
          className={`header header--desktop ${
            this.props.isWhite && " header--white "
          }`}
        >
          <div className="header__leftsection">
            <Link to="/">
              <img alt="A&D Logo" className="header__logo" src={logo} />
            </Link>
          </div>

          <div className="header__rightsection">
            <Link className="header__link header__link--dropdown" to="/#about">
              ABOUT
              <div class="header__link--dropdown__content">
                <Link to="/team/">
                  <p>TEAM</p>
                </Link>
                <Link to="/milestones/">
                  <p>MILESTONES</p>
                </Link>
              </div>
            </Link>

            <Link className="header__link" to="/#clients">
              CLIENTS
            </Link>
            <Link
              className="header__link header__link--dropdown"
              to="/projects/"
            >
              PROJECTS
              <div class="header__link--dropdown__content">
                <Link to="/projects/institutions/">
                  <p>INSTITUTIONS</p>
                </Link>
                <Link to="/projects/commercial/">
                  <p>COMMERCIAL</p>
                </Link>
                <Link to="/projects/interiors/">
                  <p>INTERIORS</p>
                </Link>
                <Link to="/projects/residential/">
                  <p>RESIDENTIAL</p>
                </Link>
                <Link to="/projects/masterplanning/">
                  <p>MASTER PLANNING</p>
                </Link>
                <Link to="/projects/landscape/">
                  <p>LANDSCAPE</p>
                </Link>
              </div>
            </Link>
            <Link className="header__link" to="/#connect">
              CONNECT
            </Link>
          </div>
        </div>
        <div className="header--mobile">
          <NavbarMobile />
        </div>
      </header>
    );
  }
}

export default Header;
